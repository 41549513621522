import { Color } from '@pafcloud/style';
import { Colors as Color_default } from './colors.default';

export const Colors = {
  ...Color_default,
  BulletDone: Color.Primitive.Accent,
  TurnoverPercentage: Color.Primitive.Accent,
  FallbackIconBackground: `linear-gradient(45deg, ${Color.Primitive.Primary}, ${Color.Primitive.PrimaryTint})`,
  FallbackIcon: Color.Primitive.Secondary,
};
